import moment from 'moment'
import React from 'react'
import { Col, ListGroupItem, Row } from 'reactstrap'


function CardMessage({ message }) {

  return (
      <ListGroupItem color={message.from_bpo ? 'dark' : 'info'} className='rounded my-2'>
        <Row>
          <Col md={12} className={message.from_bpo ? 'text-right' : 'text-left'}>
            <strong>{message.from_bpo ? 'BPO' : 'Você'}</strong>
          </Col>
          <Col md={12}>
            {message.message}
          </Col>
          <Col md={12}>
            <small className='float-right'>Enviada as {moment(message.created_at).format('DD/MM/YYYY HH:mm')}</small>
          </Col>
        </Row>
      </ListGroupItem>
  )
}

export default CardMessage
