const menuPermissions = () => {
  return {
    '/#/vendas/clientes': 'customers',
    '/#/vendas/clientes/cadastrar': 'customers',
    '/#/vendas/produtos': 'products',
    '/#/vendas/produtos/cadastrar': 'products',
    '/#/vendas/transportadoras': 'shipping_companies',
    '/#/vendas/transportadoras/cadastrar': 'shipping_companies',
    '/#/vendas/vendedores': 'sellers',
    '/#/vendas/vendedores/cadastrar': 'sellers',
    '/#/vendas/contratos': 'orders',
    '/#/vendas/contratos/cadastrar': 'orders',
    '/#/vendas/estoque': 'stock',
    '/#/vendas/inventario': 'stock',
    '/#/vendas/pdv': 'pdv',
    '/#/vendas/notas-fiscais': 'nfe',
    '/#/vendas/notas-fiscais/emitir': 'nfe',
    '/#/vendas/notas-fiscais-consumidor': 'pdv',
    '/#/vendas/manifestos-eletronicos': 'mdfe',
    '/#/vendas/manifestos-eletronicos/emitir': 'mdfe',
    '/#/vendas': 'orders',
    '/#/vendas/cadastrar': 'orders',
    '/#/vendas/grupo-produtos' : 'product_groups',
    '/#/vendas/grupo-produtos/cadastrar' : 'product_groups',
    '/#/servicos': 'services',
    '/#/servicos/cadastrar': 'services',
    '/#/servicos/ordem-servico': 'service_orders',
    '/#/servicos/ordem-servico/cadastrar': 'service_orders',
    '/#/servicos/notas-fiscais-servico': 'nfse',
    '/#/servicos/notas-fiscais-servico/emitir': 'nfse',
    '/#/compras/fornecedores': 'providers',
    '/#/compras/fornecedores/cadastrar': 'providers',
    '/#/compras/impostos': 'taxes',
    '/#/compras/impostos/cadastrar': 'taxes',
    '/#/compras/produtos': 'products',
    '/#/compras/produtos/cadastrar': 'products',
    '/#/compras/estoque': 'stock',
    '/#/compras/inventario': 'stock',
    '/#/compras/contratos': 'purchases',
    '/#/compras/contratos/cadastrar': 'purchases',
    '/#/compras/notas-fiscais': 'purchase_nfe',
    '/#/compras/grupo-produtos' : 'product_groups',
    '/#/compras/grupo-produtos/cadastrar' : 'product_groups',
    '/#/compras': 'purchases',
    '/#/compras/cadastrar': 'purchases',
    '/#/financeiro/categorias': 'categories',
    '/#/financeiro/contas': 'accounts',
    '/#/financeiro/contas/cadastrar': 'accounts',
    '/#/financeiro/contas-receber': 'installments_receive',
    '/#/financeiro/contas-receber/cadastrar': 'installments_receive',
    '/#/financeiro/contas-pagar': 'installments_pay',
    '/#/financeiro/contas-pagar/cadastrar': 'installments_pay',
    '/#/financeiro/natureza-operacao': 'financial_operation_nature',
    '/#/financeiro/natureza-operacao/cadastrar': 'financial_operation_nature',
    '/#/financeiro/extrato': 'extract',
    '/#/financeiro/centro-custo': 'cost_center',
    '/#/financeiro/projetos': 'projects',
    '/#/financeiro/nfconta': 'nfconta',
    '/#/financeiro/conciliacao': 'compensates',
    '/#/financeiro/remessas': 'installments_receive',
    '/#/financeiro/retornos': 'installments_receive',
    '/#/financeiro/historico' : 'installments_historic',
    '/#/financeiro/caixa-entrada' : 'mailbox',
    '/#/relatorios/fluxo-diario': 'daily_flow',
    '/#/relatorios/fluxo-mensal': 'monthly_flow',
    '/#/relatorios/vendas-cliente': 'customers_orders',
    '/#/relatorios/vendas-vendedor': 'sellers_orders',
    '/#/relatorios/comissao-vendedor': 'sellers_comissions',
    '/#/relatorios/mais-vendidos': 'best_products',
    '/#/relatorios/produtos-cliente': 'customers_products',
    '/#/relatorios/analise-recebimentos': 'received',
    '/#/relatorios/analise-pagamentos': 'payed',
    '/#/relatorios/clientes': 'report_customers',
    '/#/relatorios/giro-estoque': 'turning_stock',
    '/#/relatorios/posicao-contas': 'position_persons',
    '/#/relatorios/dre': 'dre',
    '/#/relatorios/dashboard-dre': 'dre',
    '/#/relatorios/dashboard-saldo-diario': 'dashboard',
    '/#/relatorios/dashboard-vendas': 'dashboard_sales',
    '/#/relatorios/simulador-lucros': 'profit_simulator',
    '/#/relatorios/automacao': 'report_automation',
    '/#/relatorios/whatsapps': 'report_automation',
    '/#/relatorios/emails': 'report_automation',
    '/#/config-empresa': 'company_settings',
    '/#/dados-empresa': 'company_profile',
    '/#/importa-dados/produtos': 'products',
    '/#/importa-dados/clientes': 'customers',
    '/#/importa-dados/fornecedores': 'providers',
    '/#/importa-dados/vendedores': 'sellers',
    '/#/importa-dados/transportadoras': 'shipping_companies',
    '/#/importa-dados/contas_pagar': 'installments_pay',
    '/#/importa-dados/contas_receber': 'installments_receive',
    '/#/importa-dados/gerenciar': 'import-data_manager',
    '/#/importa-dados/cadastros_xml': 'import-data_manager',
    '/#/meu-contador': 'contador',
    '/#/usuarios': 'users',
    '/#/usuarios/cadastrar': 'users',
  }
}

export default menuPermissions;

